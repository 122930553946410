<template>
  <div class="container">
    <h1>统计资料</h1>
    <p>“中国固废资源化技术数据库与技术绩效评价系统”采用的统计资料包括但不限于：</p>
    <p>《中国统计年鉴》（1980-2022年）</p>
    <p>《中国环境统计年鉴》（2005-2022年）</p>
    <p>《中国环境统计年报》（2005-2022年）</p>
    <p>《中国城市建设统计年鉴》（1980-2022年)</p>
    <p>《中国城市统计年鉴》（1980-2020年)</p>
    <p>《中国再生资源回收行业发展报告》（2015-2020年）</p>
    <p>《全国大、中城市固体废物污染环境防治年报》（2014-2020年）</p>
    <p>《中国塑料工业年鉴》（2000-2020年）</p>
    <p>《中国化学工业年鉴》（2000-2020年）</p>
    <p>《中国纺织工业年鉴》（2000-2020年）</p>
    <p>《中国再生塑料行业发展报告》（2017-2021年）</p>
    <p>……</p>
  </div>
</template>

<script>
export default {
  name: "Statistical"
}
</script>

<style scoped>
h1 {
  text-align: center;
}

p {
  text-indent: 1cm;
}
</style>